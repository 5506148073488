
.section-bgimg-professional {
  height: 400px;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../_images/professional/steadytemp_professional_header-cobas-pulse.jpg');

  @media only screen and (max-width: $tablet){
    background-position: 60%;
    background-image: url('../_images/professional/steadytemp_professional_header_mobile-cobas-pulse.jpg')!important;
  }
  h1 {
    font-size: 3rem;
    @media only screen and (max-width:$tablet) {
       font-size:1.5rem;
    }
  }
}

.steadytemp-video{
  width: 100%;
  height: 300px;

  @media only screen and (max-width: $tablet){
    height: 200px;
  }
}

.arrow-line{
  width: 200%;
}

.cobas-pulse {
  @media only screen and (max-width: $tablet) {
    padding-top: 13.5rem;
  }
}

ul.benefits {
  list-style-type: disc;
  padding-left: 1rem;
}


.usp1 {
  background-image: url(../_images/professional/pics/arrow.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  h3 {
    font-size: 1rem;
  }

  p {
    width: 90%;
    padding:0;
    margin-bottom:0;
  }
}

.img90 {
  width:90%;
}

.img80 {
  width:80%;
}

.img70 {
  width:80%;
}

.img11 {
  height: 15vw;
  max-height: 150px;
  @media only screen and (max-width: $tablet) {
    height:100%;
    max-height:200px;
  }
}

.blog_img_teaser {
  width:70%;
}

.blog-fix {
  width:80%;
}

.slider-item {
  margin-bottom: 2rem;
}

.slidecontainer1 {
  width: 100%;
}

.slider1 {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius:5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider1:hover {
  opacity: 1;
}

.slider1::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $gradient-right-color2;
  cursor: pointer;
}

.slider1::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.partner-btn {
  width: 65%;
}

.explain {
  width: 100%;
}

.header-prof {
  width:60%;
  margin-top: 1rem;
  margin-left: 2rem;
  @media only screen and (max-width: 500px) {
    margin-left: 0;
  }
}
.device {
  @media only screen and (min-width: $desktop) {
    width:25%
  }
}


